import React, { FC, ReactNode, SyntheticEvent, useCallback } from "react";
import classNames from "classnames";
import { compact } from "lodash";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useHasFeature from "@hooks/use-has-feature";
import useToggle from "@hooks/use-toggle";
import { useIsMobile } from "@hooks/use-window-size";
import { FeatureNames } from "@lib/constants/featureNames";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";
import { LabelType } from "@lib/data/schemas/label";
import { PersonType, SVGIconProps } from "@lib/shared-types";

import { HighlightedTextFromContext } from "@components/HighlightedText";
import IconAction from "@components/IconAction";
import AssignIcon from "@components/Icons/AssignIcon";
import AssignClientToMemberModal from "@components/Modals/AssignClientToMemberModal";

import ClientAvatar from "./Client/ClientAvatar";
import ArchiveIcon from "./Icons/ArchiveIcon";
import ChatIcon from "./Icons/ChatIcon";
import MoreIcon from "./Icons/MoreIcon";
import PencilIcon from "./Icons/PencilIcon";
import TrashIcon from "./Icons/TrashIcon";
import UnarchiveIcon from "./Icons/UnarchiveIcon";
import LabelsList from "./Labels/LabelsList";
import DeleteClientModal from "./Modals/DeleteClientModal";
import { getOffsetPosition, LabelsSelector, useLabelsSelector } from "./Labels";
import { NotificationBubble } from "./NotificationBubble";

interface PersonInformationProps {
  person: PersonType;
  extra?: ReactNode;
  StatusIcon?: FC<SVGIconProps>;
}

export const PersonInformation: FC<PersonInformationProps> = ({
  person,
  extra,
  StatusIcon,
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={classNames("flex items-center min-w-0", isMobile && "ml-4")}
    >
      <div className="relative mr-4">
        <ClientAvatar client={person} />
        {StatusIcon && (
          <StatusIcon className="absolute -top-1 -right-1 w-5 h-5" />
        )}
      </div>
      <div className="min-w-0">
        <div className="flex flex-col">
          <HighlightedTextFromContext
            className={classNames(
              "truncate",
              "status" in person && person?.status === "archived"
                ? "text-grey-500"
                : "text-black-ink"
            )}
            text={displayNameFromContact(person) || ""}
          />
          {(person.firstName || person.lastName) &&
          !["company", "family"].includes(person.clientType) ? (
            <HighlightedTextFromContext
              className="hidden sm:block truncate text-sm text-gray-500"
              text={person.email}
            />
          ) : null}
          {extra}
        </div>
      </div>
    </div>
  );
};

interface ContactProps {
  contact: ClientType;
  toggleArchive: (contact: ClientType) => void;
  displayNotification: boolean;
  userId: string;
  checked: boolean;
  onClickLabel: (label: any) => void;
  selectable: boolean;
}

const Contact: FC<ContactProps> = ({
  contact,
  toggleArchive,
  displayNotification,
  userId,
  checked,
  onClickLabel,
  selectable,
}) => {
  const isMobile = useIsMobile();
  const { oid } = useAuth();
  const isIndividual = contact.clientType === "individual";
  const [hasMembersFeature] = useHasFeature(oid, FeatureNames.members);
  const { hasFullAccess } = useAccessType();
  // TODO, should be allowed, waiting for merge
  const canArchiveContacts = isIndividual && hasFullAccess;
  const canDeleteContacts = isIndividual && hasFullAccess;
  const canAssignContacts = isIndividual && hasMembersFeature && hasFullAccess;
  const canOpenChat = isIndividual;

  const {
    selectorIsOpen,
    contactLabelsIds,
    toggleLabel,
    createLabel,
    contactLabels,
    labels,
    setSelectorIsOpen,
    selectorPosition,
    setSelectorPosition,
    updating,
  } = useLabelsSelector({ userId: oid, contact, ref: undefined });
  const { value: showAssignClientModal, toggle: toggleAssignClientModal } =
    useToggle();
  const { value: showDeleteModal, toggle: toggleDeleteModal } = useToggle();

  const router = useRouter();

  const handleArchiveClick = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      toggleArchive(contact);
    },
    [contact, toggleArchive]
  );

  const handleClickOpenLabel = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      e.preventDefault();
      setSelectorPosition(
        getOffsetPosition({
          e,
          isMobile,
          buttonWidth: 28,
          buttonHeight: 38,
          forceRightSide: true,
        })
      );
      setSelectorIsOpen(true);
    },
    [setSelectorPosition, isMobile, setSelectorIsOpen]
  );

  const handleClickLabel = useCallback(
    (label: LabelType, e: SyntheticEvent<HTMLDivElement>) => {
      e.preventDefault();
      onClickLabel(label?.title);
    },
    []
  );

  const isContactDeleted = contact?.status === "archived";
  const options = compact([
    {
      type: "button",
      title: "Edit",
      Icon: PencilIcon,
      onClick: () =>
        router.push(
          isIndividual
            ? `/contacts/${contact.id}/edit`
            : `/client-organizations/${contact.id}/edit`
        ),
    },
    canAssignContacts && {
      type: "button",
      Icon: AssignIcon,
      title: "Assign",
      onClick: toggleAssignClientModal,
    },
    canOpenChat && {
      type: "button",
      onClick: () =>
        window.open(`me/${userId}/clients/${contact?.id}/messaging`, "_blank"),
      title: "Open chat",
      Icon: ChatIcon,
    },
    canArchiveContacts && {
      type: "button",
      title: isContactDeleted ? "Unarchive" : "Archive",
      Icon: isContactDeleted ? UnarchiveIcon : ArchiveIcon,
      onClick: handleArchiveClick,
    },
    canDeleteContacts && {
      type: "button",
      title: "Delete",
      Icon: TrashIcon,
      onClick: toggleDeleteModal,
      variant: "delete",
    },
  ]);

  const renderActionButtons = (
    <IconAction
      className="hover:bg-action-700 mr-3 sm:mr-0"
      Icon={MoreIcon}
      dropdownItems={options}
      type="dropdown"
    />
  );

  const renderLabelSelector = (
    <LabelsSelector
      isOpen={selectorIsOpen}
      labels={labels}
      labelsSelected={contactLabelsIds}
      onClickLabel={toggleLabel}
      onClickCreate={createLabel}
      onClickBackdrop={setSelectorIsOpen}
      position={selectorPosition}
      className="mt-8"
      withBackdrop={true}
      updating={updating ?? undefined}
    />
  );

  const renderNotificationBubble = displayNotification && (
    <NotificationBubble
      size={isMobile ? "smaller" : "small"}
      className={classNames(
        "absolute self-center",
        !isMobile && "-ml-7",
        selectable && "group-hover:invisible",
        checked && "invisible"
      )}
    />
  );

  const activity = (
    <div className="flex leading-6 text-sm">
      <span className="text-grey-500 shrink-0">
        {moment(contact.latestMergedActivity.date).format("ll")}
      </span>

      <span className="mx-1 font-bold">·</span>
      <span
        className={classNames(
          "truncate",
          `text-${contact.latestMergedActivity.color}`
        )}
      >
        {contact.latestMergedActivity.name}
      </span>
    </div>
  );

  // TODO href to client-organizations
  return (
    <div className="flex-1">
      <Link
        href={
          isIndividual
            ? `contacts/${contact?.id}`
            : `client-organizations/${contact?.id}`
        }
        className="block relative"
        id={`client-list-item-${contact?.id}`}
      >
        <div className="flex items-center py-4 sm:px-4">
          <div className="min-w-0 flex-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 relative">
            {renderNotificationBubble}
            <PersonInformation
              person={contact}
              extra={<div className="sm:hidden text-ellipsis">{activity}</div>}
            />
            <div className="hidden sm:flex items-center text-ellipsis">
              {activity}
            </div>
            {!isMobile && (
              <LabelsList
                labels={contactLabels}
                className="relative flex-nowrap sm:col-span-2 md:col-span-1  md:justify-end items-center"
                itemClassName={classNames(
                  "group-hover:bg-action-950",
                  checked && "bg-action-950"
                )}
                onClickAdd={handleClickOpenLabel}
                onClickLabel={handleClickLabel}
              />
            )}
          </div>
          <div className="hidden sm:block relative w-12 h-12">
            {renderActionButtons}
          </div>
        </div>
      </Link>
      {!isMobile && renderLabelSelector}
      {showAssignClientModal && (
        <AssignClientToMemberModal
          show
          toggleShow={toggleAssignClientModal}
          client={contact}
        />
      )}
      {showDeleteModal && (
        <DeleteClientModal
          show
          toggleShow={toggleDeleteModal}
          clientId={contact?.id}
        />
      )}
    </div>
  );
};

export default Contact;
